
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiAccount } from "@/core/api";
import { MBTaggings } from "magicbean-saas-common";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

export default defineComponent({
  name: "account-profile",
  components: {
    MBTaggings,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();

    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    // Capability
    const productTaggingsRef = ref<null | HTMLFormElement>(null);
    const salesTaggingsRef = ref<null | HTMLFormElement>(null);
    const fulfillmentTaggingsRef = ref<null | HTMLFormElement>(null);
    // Growth
    const relationshipTaggingsRef = ref<null | HTMLFormElement>(null);
    const travelRetailTaggingsRef = ref<null | HTMLFormElement>(null);
    const influencerActivityTaggingsRef = ref<null | HTMLFormElement>(null);
    const influencerContributionTaggingsRef = ref<null | HTMLFormElement>(null);
    const influencerAcceptanceTaggingsRef = ref<null | HTMLFormElement>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      account_id: route.params.id,
      calcuation_model_data: [],
    });

    // Capability
    const productCapabilityOptions = ref([]);
    const productCapabilityValues = ref([]);
    const salesCapabilityOptions = ref([]);
    const salesCapabilityValues = ref([]);
    const fulfillmentCapabilityOptions = ref([]);
    const fulfillmentCapabilityValues = ref([]);
    // Growth
    const relationshipGrowthOptions = ref([]);
    const relationshipGrowthValues = ref([]);
    const relationshipGrowthTravelRetailOptions = ref([]);
    const relationshipGrowthTravelRetailValues = ref([]);
    const influencerActivityOptions = ref([]);
    const influencerActivityValues = ref([]);
    const influencerContributionOptions = ref([]);
    const influencerContributionValues = ref([]);
    const influencerAcceptanceOptions = ref([]);
    const influencerAcceptanceValues = ref([]);

    const getDropdownOptions = async () => {
      const { data } = await ApiAccount.getAccountGroupSourceData({
        short_key: [
          // Capability
          "product_capability",
          "sales_capability",
          "fulfillment_capability",
          // Growth
          "relationship_growth",
          "relationship_growth_travel_retail",
          "influencer_activity",
          "influencer_contribution",
          "influencer_acceptance",
        ],
      });
      if (data.code == 0) {
        // Capability
        productCapabilityOptions.value = data.data.product_capability.items;
        salesCapabilityOptions.value = data.data.sales_capability.items;
        fulfillmentCapabilityOptions.value =
          data.data.fulfillment_capability.items;
        // Growth
        relationshipGrowthOptions.value = data.data.relationship_growth.items;
        relationshipGrowthTravelRetailOptions.value =
          data.data.relationship_growth_travel_retail.items;
        influencerActivityOptions.value = data.data.influencer_activity.items;
        influencerContributionOptions.value =
          data.data.influencer_contribution.items;
        influencerAcceptanceOptions.value =
          data.data.influencer_acceptance.items;
      }
    };

    const getAccountCalcuationModelData = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getAccountCalcuationModelData({
        account_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        // Capability
        productCapabilityValues.value =
          data.data.calcuation_model_data.product_capability?.items || [];
        salesCapabilityValues.value =
          data.data.calcuation_model_data.sales_capability?.items || [];
        fulfillmentCapabilityValues.value =
          data.data.calcuation_model_data.fulfillment_capability?.items || [];
        // Growth
        relationshipGrowthValues.value =
          data.data.calcuation_model_data.relationship_growth?.items || [];
        relationshipGrowthTravelRetailValues.value =
          data.data.calcuation_model_data.relationship_growth_travel_retail
            ?.items || [];
        influencerActivityValues.value =
          data.data.calcuation_model_data.influencer_activity?.items || [];
        influencerContributionValues.value =
          data.data.calcuation_model_data.influencer_contribution?.items || [];
        influencerAcceptanceValues.value =
          data.data.calcuation_model_data.influencer_acceptance?.items || [];
      }
    };

    const backToList = () => {
      if (route.path.indexOf("account-conversions") != -1) {
        router.push("/interactive-marketing/account-conversions");
      } else {
        router.push("/influencer-platform/influencer-accounts");
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          let taggingArr: Array<TaggingValue> = [];

          let productData = productTaggingsRef.value?.formData;
          let productitems: Array<TaggingValueItem> = [];
          for (let item in productData) {
            productitems.push({
              short_key: item,
              value: productData[item],
            });
          }
          let productObj: TaggingValue = {
            short_key: "product_capability",
            items: productitems,
          };
          taggingArr.push(productObj);

          let salesData = salesTaggingsRef.value?.formData;
          let salesitems: Array<TaggingValueItem> = [];
          for (let item in salesData) {
            salesitems.push({
              short_key: item,
              value: salesData[item],
            });
          }
          let salesObj: TaggingValue = {
            short_key: "sales_capability",
            items: salesitems,
          };
          taggingArr.push(salesObj);

          let fulfillmentData = fulfillmentTaggingsRef.value?.formData;
          let fulfillmentitems: Array<TaggingValueItem> = [];
          for (let item in fulfillmentData) {
            fulfillmentitems.push({
              short_key: item,
              value: fulfillmentData[item],
            });
          }
          let fulfillmentObj: TaggingValue = {
            short_key: "fulfillment_capability",
            items: fulfillmentitems,
          };
          taggingArr.push(fulfillmentObj);

          let relationshipData = relationshipTaggingsRef.value?.formData;
          let relationshipitems: Array<TaggingValueItem> = [];
          for (let item in relationshipData) {
            relationshipitems.push({
              short_key: item,
              value: relationshipData[item],
            });
          }
          let relationshipObj: TaggingValue = {
            short_key: "relationship_growth",
            items: relationshipitems,
          };
          taggingArr.push(relationshipObj);

          let travelRetailData = travelRetailTaggingsRef.value?.formData;
          let travelRetailitems: Array<TaggingValueItem> = [];
          for (let item in travelRetailData) {
            travelRetailitems.push({
              short_key: item,
              value: travelRetailData[item],
            });
          }
          let travelRetailObj: TaggingValue = {
            short_key: "relationship_growth_travel_retail",
            items: travelRetailitems,
          };
          taggingArr.push(travelRetailObj);

          let influencerActivityData =
            influencerActivityTaggingsRef.value?.formData;
          let influencerActivityitems: Array<TaggingValueItem> = [];
          for (let item in influencerActivityData) {
            influencerActivityitems.push({
              short_key: item,
              value: influencerActivityData[item],
            });
          }
          let influencerActivityObj: TaggingValue = {
            short_key: "influencer_activity",
            items: influencerActivityitems,
          };
          taggingArr.push(influencerActivityObj);

          let influencerContributionData =
            influencerContributionTaggingsRef.value?.formData;
          let influencerContributionitems: Array<TaggingValueItem> = [];
          for (let item in influencerContributionData) {
            influencerContributionitems.push({
              short_key: item,
              value: influencerContributionData[item],
            });
          }
          let influencerContributionObj: TaggingValue = {
            short_key: "influencer_contribution",
            items: influencerContributionitems,
          };
          taggingArr.push(influencerContributionObj);

          let influencerAcceptanceData =
            influencerAcceptanceTaggingsRef.value?.formData;
          let influencerAcceptanceitems: Array<TaggingValueItem> = [];
          for (let item in influencerAcceptanceData) {
            influencerAcceptanceitems.push({
              short_key: item,
              value: influencerAcceptanceData[item],
            });
          }
          let influencerAcceptanceObj: TaggingValue = {
            short_key: "influencer_acceptance",
            items: influencerAcceptanceitems,
          };
          taggingArr.push(influencerAcceptanceObj);

          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiAccount.updateAccountCalcuationModelData({
            ...formData.value,
            calcuation_model_data: taggingArr,
          });
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    onBeforeMount(() => {
      getAccountCalcuationModelData();
      getDropdownOptions();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formRef,
      submitButton,
      productTaggingsRef,
      salesTaggingsRef,
      fulfillmentTaggingsRef,
      relationshipTaggingsRef,
      travelRetailTaggingsRef,
      influencerActivityTaggingsRef,
      influencerContributionTaggingsRef,
      influencerAcceptanceTaggingsRef,
      loading,
      formData,
      // Capability
      productCapabilityOptions,
      productCapabilityValues,
      salesCapabilityOptions,
      salesCapabilityValues,
      fulfillmentCapabilityOptions,
      fulfillmentCapabilityValues,
      // Growth
      relationshipGrowthOptions,
      relationshipGrowthValues,
      relationshipGrowthTravelRetailOptions,
      relationshipGrowthTravelRetailValues,
      influencerActivityOptions,
      influencerActivityValues,
      influencerContributionOptions,
      influencerContributionValues,
      influencerAcceptanceOptions,
      influencerAcceptanceValues,
      backToList,
      submit,
    };
  },
});
